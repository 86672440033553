<template>
  <v-card v-if="availableLanguages.length" tile flat>
    <v-alert
      v-if="error.show"
      icon="mdi-alert-circle"
      dismissible
      text
      type="error"
    >
      {{ error.message }}
    </v-alert>
  
    <v-expansion-panels v-model="panel" tile flat multiple>

      <!-- DocumentItemTranslation -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row no-gutters>
            <v-col cols="8">
              <span v-if="documentItemTranslation.title">
                <strong>{{ documentItemTranslation.title }}</strong><br/>
                <span class="caption">Document Item Translation</span>
              </span>
              <span v-else>Document Item Translation</span>
            </v-col>
            <v-col v-if="sections.documentItemTranslation.pendingChanges" cols="4" class="text-right pr-2">
              <span class="orange--text caption">Unsaved Changes</span>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>

          <ImageManager
            v-if="isEnableTranslationImage && (documentItemTranslation && Object.keys(documentItemTranslation).length)"
            :id="documentItemTranslation.id"
            :connection-type-id="connectionType.documentItemTranslations"
            :allow-upload="true"
          />
          <DocumentItemTranslationsForm 
            v-if="documentItemTranslation && Object.keys(documentItemTranslation).length" 
            :document-item-translation="documentItemTranslation" 
            :save="saveDocumentItemTranslation"
            @pendingChanges="changes('documentItemTranslation', $event)"
          />

        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="renderField(documentItem.documentitemtypeid, 'author')">
        <v-expansion-panel-header>
          <v-row no-gutters>
            <v-col cols="8">
              <span v-if="author">
                <strong>Author: </strong>
                <router-link :to="{ name: 'person_home', params: { personid: author.personid }}">
                  {{ author.englishname }}
                </router-link>
              </span>
              <span v-else><strong>Add Author</strong></span>
            </v-col>
            <v-col v-if="sections.author.pendingChanges" cols="4" class="text-right pr-2">
              <span class="orange--text caption">Unsaved Changes</span>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <AuthorForm 
            :item-author="author" 
            :save="saveAuthor"
            @pendingChanges="changes('author', $event)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <strong>Grouping</strong>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <GroupingForm
            v-if="documentItemTranslation && documentItemTranslation.languageid"
            :parent-document-item-id="parentDocumentItemId"
            :source-document-item-id="documentItemTranslation.documentitemid"
            :language-id="documentItemTranslation.languageid"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
    
    <debug v-if="debug">
      <template slot="sub-heading">DocumentItemTranslationForm</template>
      <template slot="info">
        documentItem={{ documentItem }},<br/><br/>
        documentItemTranslation={{ documentItemTranslation }},<br/><br/>
        isNewRecord={{ isNewRecord }}<br/><br/>
        authorConnection= {{ authorConnection }}<br/>
        languages={{ languages }}<br/>
        availableLanguages={{ availableLanguages }}
      </template>
    </debug>
  </v-card>
  <v-alert
    v-else
    border="bottom"
    color="pink darken-1"
    dark
    flat
    tile
  >
    languages={{ languages }}
    No more translations can be added as all languages have been allocated.
  </v-alert>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { required, max, regex } from 'vee-validate/dist/rules'
import configs from '@/configs'
import Vue from 'vue'
import EditorField from '@/components/shared/EditorField'
import GroupingForm from '@/components/forms/GroupingForm'
import ImageManager from '@/components/ImageManager'
import AuthorForm from '@/components/forms/AuthorForm'
import Debug from '@/components/common/Debug'
import _ from 'lodash'
import DocumentItemTranslationsForm from '@/components/documentItemTranslations/forms/DocumentItemTranslationsForm.vue'
import DocAdmin from '@/store/data'
import eBus from '@/eventbus'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})
  
export default {
  name: 'DocumentItemTranslationEditorPage',
  components: {
    ValidationObserver,
    ValidationProvider,
    Debug,
    EditorField,
    GroupingForm,
    ImageManager,
    DocumentItemTranslationsForm,
    AuthorForm
  },
  data() {
    return {
      panel: [0],
      connectionType: configs.connectionProperties.types,
      error: {
        show: false,
        message: ''
      },
      author: null,
      authorConnection: null,
      sections: {
        author: {
          pendingChanges: false
        },
        documentItemTranslation: {
          pendingChanges: false
        },
        terms: {
          pendingChanges: false
        }
      }
    }
  },
  computed: {
    ...mapState('app', ['debug']),
    ...mapState('languages', ['languages']),
    ...mapState('documentItems', ['documentItem' , 'documentItemTranslations', 'isLeafNode']),
    ...mapGetters('languages', ['languageNameById']),
    ...mapGetters('documents', ['renderComponent', 'renderTranslationField', 'isEnableTranslationImage', 'renderField']),
    ...mapGetters('documentItems', ['getDocumentItemTranslationsById']),
    parentDocumentItemId() {
      if (this.documentItem.parentdocumentitemid) {
        return this.documentItem.parentdocumentitemid
      }
      return this.documentItemTranslation.documentitemid 
    },
    documentItemTranslation() {
      const documentItemTranslationId = this.$route.params.documentitemtranslationid

      return this.getDocumentItemTranslationsById(documentItemTranslationId)
    },
    availableLanguages() {
      if (!this.languages.length > 0) { return []}
      const items = this.documentItemTranslations.map((el) => { return el.languageid })
      const useableLanguages = this.languages.filter((language) => {
        if (this.documentItemTranslation.languageid && (Number(language.languageid) === Number(this.documentItemTranslation.languageid))) { return language }
        if (!items.includes(language.languageid)) { return language }
      })

      return useableLanguages ? useableLanguages : this.languages
    },
    isNewRecord () {
      return !(this.documentItemTranslation && this.documentItemTranslation.id)
    },
    documentItemTypeId() {
      return this.documentItem.documentitemtypeid
    }
  },
  created() {
    this.loadData()
    this.getAuthorConnection()
  },
  beforeRouteLeave(to, from, next) {
    if (this.pendingChanges) {
      const continute = confirm('You have unsaved changes are you sure you want to leave?');
      if (continute) { next() }
      next(false)
    } else {
      next()
    }
  },
  methods: {
    ...mapActions('documentItems', ['createDocumentItemTranslation', 'updateDocumentItemTranslation']),
    emitUpdateDocumentItemTranslation(field, value) {
      console.log(`emitUpdateDocumentItemTranslation, field=${field}, value=${value}`)
      eBus.$emit('update_document_item_translation', { field, value })
    },
    async getAuthorConnection() {
      console.log('DocumentItemTranslationEditorPage.getAuthorConnection')

      const results = await this.getConnections({
        destination_connectiontypeid: this.connectionType.persons,
        connectionpurposeid: configs.connectionProperties.purpose.author
      })

      if (results.data.results.length > 0) {
        const personId = results.data.results[0].destinationid
        const personObject = await DocAdmin.persons.get(personId)

        this.author = personObject.data
        this.authorConnection = results.data.results[0]
      }
    },
    async getConnections(params) {
      params.sourceid = this.$route.params.documentitemtranslationid
      params.source_connectiontypeid = this.connectionType.documentItemTranslations

      return DocAdmin.connections.list({ params: params } )
    },
    async updateConnections(id, params) {
      params.sourceid = this.$route.params.documentitemtranslationid
      params.source_connectiontypeid = this.connectionType.documentItemTranslations

      return DocAdmin.connections.update(id, params)
    },
    async addConnection(params) {
      params.sourceid = this.$route.params.documentitemtranslationid
      params.source_connectiontypeid = this.connectionType.documentItemTranslations

      return DocAdmin.connections.create(params)
    },
    async removeConnection(id) {
      await DocAdmin.connections.remove(id)
    },
    async saveDocumentItemTranslation(documentItemTranslation) {
      try {
        await this.updateDocumentItemTranslation({ id: documentItemTranslation.id, payload: documentItemTranslation })
        this.$store.dispatch('app/setSnackBar', 'Document Item Translation updated.')
        this.$router.push({
          name: 'documentitem_edit',
          params: {
            documentitemid: documentItemTranslation.documentitemid
          }
        })
      } catch (err) {
        this.error.show = true
        this.error.message = `There was a failure in updating the record. Details: ${err}`
      }
    },
    async saveAuthor(person) {

      this.author = person

      if (!Object.keys(person).length && (this.authorConnection && Object.keys(this.authorConnection).length)) {
        console.log(`DocumentItemTranslationEditorPage.saveAuthor.removeConnection=${this.authorConnection.sourceid}`)
        this.removeConnection(this.authorConnection.sourceid)
        this.emitUpdateDocumentItemTranslation('englishtitle', '')

        return
      }

      if (person && Object.keys(person).length) {

        if (this.authorConnection && Object.keys(this.authorConnection).length) {
          console.log(`DocumentItemTranslationEditorPage.saveAuthor.updateConnections=${this.authorConnection.sourceid}`)
          await this.updateConnections(this.authorConnection.sourceid, {
            destinationid: person.personid,
            destination_connectiontypeid: this.connectionType.persons,
            connectionpurposeid: configs.connectionProperties.purpose.author
          })
          const title = `Translated in ${this.languageNameById(this.documentItemTranslation.languageid)} By ${person.englishname}`

          this.emitUpdateDocumentItemTranslation('title', `Commentary By ${title}` )

          return
        }

        if (!this.authorConnection && person) {
          console.log(`DocumentItemTranslationEditorPage.saveAuthor.addConnection=${person.personid}`)
          this.addConnection({
            destinationid: person.personid,
            destination_connectiontypeid: this.connectionType.persons,
            connectionpurposeid: configs.connectionProperties.purpose.author
          })
          this.emitUpdateDocumentItemTranslation('englishtitle', `Commentary By ${person.englishname}` )
        }
      }

    },
    async loadData() {
      if (!this.documentItemTranslation) {
        await this.$store.dispatch('documentItems/getDocumentItemWithChildren', this.$route.query.documentitemid)
      }
      this.loading = false
    },
    changes (section, changes) {
      console.log(`section=${section}, changed=${changes}`)
      Vue.set(this.sections[section], 'pendingChanges', changes)
    }
  }
}
</script>