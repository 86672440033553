<template>
  <div v-if="!loading">
    <v-row v-for="groupType in groupingTypes" :key="groupType.name" justify="start" no-gutters>
      <v-col cols="12">
        <v-alert
          v-if="Object.keys(error).length && error[groupType.name].show"
          icon="mdi-alert-circle"
          dismissible
          text
          type="error"
        >
          {{ error[groupType.name].message }}
        </v-alert>
        <small v-if="Object.keys(grouping).length > 0 && grouping[groupType.name]">({{ grouping[groupType.name].length }}) item(s) configured</small>
        <DocumentItemLookupField
          :parent-document-item-id="parentDocumentItemId"
          :selected="selected[groupType.name]"
          :label="`${groupType.label}, hint: ${groupType.hint}`"
          @addItem="addGroupDocumentItem($event, groupType.name)"
          @removeItem="removeGroupDocumentItem($event, groupType.name, true)"
        />
      </v-col>
    </v-row>
  </div>
  <div v-else>Loading...</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import DocumentItemLookupField from '@/components/shared/DocumentItemLookupField'
import DocAdmin from '@/store/data'
import Vue from 'vue'

export default {
  name: 'GroupingForm',
  components: {
    DocumentItemLookupField
  },
  props: {
    parentDocumentItemId: {
      type: [String, Number],
      default: null
    },
    sourceDocumentItemId: {
      type: [String, Number],
      default: ''
    },
    languageId: {
      type: [String, Number],
      default: null
    }
  },
  data: () => ({
    selected: {},
    grouping: {},
    loading: false,
    saving: false,
    error: {},
    // SEPARATE_PARAGRAPHS = "separateParagraphs"
    // COMBINE_TO_ONE_PARAGRAPH = "combineToOneParagraph"
    groupingTypes: [
      { 
        name: 'separateParagraphs', 
        label: 'Separate Paragraphs Grouped',
        hint: 'Shrink many shloks to 1'
      },
      { 
        name: 'combineToOneParagraph', 
        label: 'Joined Paragraphs',
        hint: 'For print or display purpose when you want to combine paragraphs' 
      }
    ]
  }),
  computed: {
    ...mapGetters('documentItems', ['getDocumentItemsById']),
    ...mapState('documentItems', ['dilookupChildDocumentItems']),
  },
  mounted() {
    this.$nextTick(() => {
      this.groupingTypes.forEach((i) => {
        Vue.set(this.error, i.name, {
            show: false,
            message: ''
          })
          Vue.set(this.selected, i.name, [])
      })
      this.loadGrouping()
    })
  },
  methods: {
    addGroupDocumentItem(v, groupType) {
      this.selected[groupType] = v      
      this.saveItem(v.at(-1), groupType)
    },
    removeGroupDocumentItem(val, groupType, deleteChip) {
      const foundIndex = this.selected[groupType].findIndex((x) => x.documentitemid === val)

      if (foundIndex !== -1) {
        if (deleteChip) {
          this.deleteItem(this.selected[groupType][foundIndex], groupType)
        }
        this.selected[groupType].splice(foundIndex, 1)
      }
    },
    /**
     * Method adds a key `documentitemgroupingsid` to the documentitem so we know how to delete it.
     * 
     * @param {String} groupType 
     */
    markDocumentItemWithGroupingId(groupType) {
      if (!this.selected[groupType].length) { return }
      this.grouping[groupType].forEach((groupItem) => {
        const diIndex = this.selected[groupType].findIndex((documentItem) => { 
          return parseInt(documentItem.documentitemid) === parseInt(groupItem.destination_documentitemid)
        })

        if (diIndex === -1) { return }
        Vue.set(this.selected[groupType][diIndex], 'documentitemgroupingsid', groupItem.documentitemgroupingsid)
      })
    },
    loadGrouping() {
      this.loading = true
      this.groupingTypes.forEach((groupType) => {

        DocAdmin.documentItemGroupings.list({ params: { 
          source_documentitemid: this.sourceDocumentItemId,
          render: groupType.name,
          languageid: this.languageId
        }}).then((groupingData) => {
          this.grouping[groupType.name] = groupingData.data.results
          if (groupingData.data.results.length === 0) { return }
          this.selected[groupType.name] = this.grouping[groupType.name].reduce((result, groupItem) => {
            if (groupItem.languageid === parseInt(this.languageId) && groupItem.render === groupType.name) {
              DocAdmin.documentItems.get(groupItem.destination_documentitemid).then((d) => {
                result.push(d.data)
                this.markDocumentItemWithGroupingId(groupType.name)
              })
            }
            return result
          }, [])
        })
      })
      this.loading = false
    },
    saveItem(documentItem, groupType) {

      if (parseInt(this.sourceDocumentItemId) === parseInt(documentItem.documentitemid)) {
        this.error[groupType].show = true
        this.error[groupType].message = 'Cant add self to the group'
        this.removeGroupDocumentItem(documentItem.documentitemid, groupType, false)

        return
      }

      const groupItemPayload = {
        languageid: this.languageId,
        source_documentitemid: this.sourceDocumentItemId,
        destination_documentitemid: documentItem.documentitemid,
        render: groupType
      }

      DocAdmin.documentItemGroupings.create(groupItemPayload).then((res) => {
        try {
          this.grouping[groupType].push(res.data)
          this.markDocumentItemWithGroupingId(groupType)
        } catch (error) {
          this.error[groupType].show = true
          this.error[groupType].message = `Failed to save item to group, error=${error}`
          this.removeGroupDocumentItem(documentItem.documentitemid, groupType, false)
        }
      }).catch((d) => {
        this.error[groupType].show = true
        this.error[groupType].message = `Failed to save item to group, error=${JSON.stringify(d.response.data)}`
        this.removeGroupDocumentItem(documentItem.documentitemid, groupType, false)
      })
    },
    deleteItem(documentItem, groupType) {
      if (!documentItem.documentitemgroupingsid) {
        this.error[groupType].show = true
        this.error[groupType].message = 'Dont know how to delete this item, missing documentitemgroupingsid'

        return
      }
      DocAdmin.documentItemGroupings.remove(documentItem.documentitemgroupingsid).catch((d) => {
        this.error[groupType].show = true
        this.error[groupType].message = `Failed to delete item from group, error=${JSON.stringify(d.response.data)}`
        this.selected[groupType].push(documentItem)
      })
    }
  }
}
</script>