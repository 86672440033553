<template>
  <v-card v-if="availableLanguages.length" tile flat>
    <v-alert
      v-if="error.show"
      icon="mdi-alert-circle"
      dismissible
      text
      type="error"
    >
      {{ error.message }}
    </v-alert>
    <v-card-text>
      <ImageManager
        v-if="isRenderComponent('enableTranslationImage')"
        :id="documentItemTranslationLocal.id"
        :connection-type-id="connectionType.documentItemTranslations"
        :allow-upload="true"
      />

      <ValidationObserver ref="document_item_translation" tag="form" :disabled="saving">
        
        <v-row justify="start" no-gutters>
          <v-col v-if="renderField('title')">
            <ValidationProvider v-slot="{ errors }" name="title" :rules="rules('title', 'required|max:150')">
              <v-text-field 
                v-model="documentItemTranslationLocal.title"
                label="Title" 
                :error-messages="errors"
                data-vv-name="title"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="3"
            xl="3"
          >
            <ValidationProvider v-slot="{ errors }" name="languageid" rules="required">
              <v-select
                v-model="documentItemTranslationLocal.languageid"
                :error-messages="errors"
                :items="availableLanguages"
                :item-text="item => item.englishname"
                :item-value="item => item.languageid"
                label="Language"
                required
                data-vv-name="languageid"
                single-line
              />
            </ValidationProvider>
          </v-col>
          <v-col 
            cols="12"
            sm="6"
            md="3"
            lg="3"
            xl="3"
          >
            <ValidationProvider v-slot="{ errors }" name="documentitemid" rules="required">
              <v-text-field 
                v-model="documentItemTranslationLocal.documentitemid"
                type="number"
                label="Document Item Id" 
                :error-messages="errors"
                data-vv-name="documentitemid"
                required
                @input="updateLocal('documentitemid', $event)"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        
        <v-row v-if="renderField('body')" justify="start" no-gutters>
          <v-col cols="12">
            <EditorField 
              :editor-id="`body-${String(_uid)}`" 
              :content="documentItemTranslationLocal.body"
              @update="updateLocal('body', $event)"
            />
            <span v-if="documentItemTranslationLocal.title" class="text-xs caption">{{ documentItemTranslationLocal.title }}</span>
          </v-col>
        </v-row>

        <v-row v-if="renderField('description')" justify="start" no-gutters>
          <v-col cols="12">
            <EditorField 
              :editor-id="`description-${String(_uid)}`" 
              :content="documentItemTranslationLocal.description"
              label="Description"
              @update="updateLocal('description', $event)"
            />
          </v-col>
        </v-row>

        <v-row justify="start" no-gutters>
          <v-col
            v-if="renderField('acronym')"
            cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >
            <v-text-field 
              v-model="documentItemTranslationLocal.acronym"
              label="Acronym" 
            />
          </v-col>
          <v-col
            v-if="renderField('acronymlipi')"
            cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >
            <v-text-field 
              v-model="documentItemTranslationLocal.acronymlipi"
              label="Acronym Lipi" 
            />
          </v-col>
          <v-col
            v-if="renderField('acronymphonetic')"
            cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >
            <v-text-field 
              v-model="documentItemTranslationLocal.acronymphonetic"
              label="Acronym Phonetic" 
            />
          </v-col>
        </v-row>

      </ValidationObserver>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="determineSave" :disabled="!changed">{{ buttonText }}</v-btn>
      <v-btn v-if="!isNewRecord" color="error" @click="remove()">Delete</v-btn>
    </v-card-actions>

    <debug v-if="debug">
      <template slot="sub-heading">DocumentItemTranslationForm</template>
      <template slot="info" class="text-xs">
        documentItem={{ documentItem }},<br/><br/>
        documentItemTranslationLocal={{ documentItemTranslationLocal }},<br/><br/>
        changed={{ changed }},<br/><br/>
        isNewRecord={{ isNewRecord }}
      </template>
    </debug>
  </v-card>
  <v-alert
    v-else
    border="bottom"
    color="pink darken-1"
    dark
    flat
    tile
  >
    No more translations can be added as all languages have been allocated, 
    <router-link :to="{ name: 'documentitem_translations' }">view translations</router-link>
  </v-alert>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { required, max, regex } from 'vee-validate/dist/rules'
import configs from '@/configs'
import Vue from 'vue'
import EditorField from '@/components/shared/EditorField'
import ImageManager from '@/components/ImageManager'
import Debug from '@/components/common/Debug'
import eBus from '@/eventbus'

import _ from 'lodash'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

export default {
  name: 'DocumentItemTranslatiomsForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    Debug,
    EditorField,
    ImageManager
  },
  props: {
    documentItemTranslation: {
      type: Object
    },
    save: {
      type: Function
    },
    buttonText: {
      type: String,
      default: 'Save'
    }
  },
  data() {
    return {
      connectionType: configs.connectionProperties.types,
      error: {
        show: false,
        message: ''
      },
      changed: false,
      saving: false,
      documentItemTranslationLocal: {}
    }
  },
  computed: {
    ...mapState('app', ['debug']),
    ...mapState('languages', ['languages']),
    ...mapState('account', ['isSuperUser']),
    ...mapGetters('documents', ['renderComponent', 'renderTranslationField', 'itemTranslationFieldRules']),
    ...mapState('documentItems', ['documentItem' , 'documentItemTranslations']),
    ...mapGetters('documentItems', ['getDocumentItemTranslationsById']),
    availableLanguages() {
      if (!this.languages) { return }

      const myDocumentItemId = this.documentItemTranslationLocal.documentitemid
      const items = this.documentItemTranslations.filter((el) => el.documentitemid === myDocumentItemId).map((el) => el.languageid)

      const useableLanguages = this.languages.filter((language) => {
        if (this.documentItemTranslationLocal.languageid && (Number(language.languageid) === Number(this.documentItemTranslationLocal.languageid))) { return language }
        if (!items.includes(language.languageid)) { return language }
      })
      
      return useableLanguages ? useableLanguages : this.languages
    },
    isNewRecord () {
      return !this.documentItemTranslationLocal.id
    },
    documentItemTypeId() {
      return this.documentItem.documentitemtypeid
    }
  },
  created() {
    eBus.$on('update_document_item_translation', ({ field, value }) => {
      Vue.set(this.documentItemTranslationLocal, field, value)
    })
    if (!this.$route.params.documentitemtranslationid) { 
      this.loading = false
      this.initData()
      return 
    }
    this.loaddata()
  },
  watch: {
    documentItemTranslationLocal: {
      deep: true,
      handler(a) {
        const src = this.getDocumentItemTranslationsById(this.$route.params.documentitemtranslationid)
        if (JSON.stringify(src) !== JSON.stringify(a)) {
          this.changes(true)
          return
        }
        this.changes(false)
      }
    }
  },
  methods: {
    ...mapActions('documentItems', ['createDocumentItemTranslation', 'updateDocumentItemTranslation', 'deleteDocumentItemTranslation']),
    renderField (fieldName) {
      if (!this.documentItemTypeId) {
        return false
      }

      return this.renderTranslationField(this.documentItemTypeId, fieldName)
    },
    rules(fieldName, rule) {
      return this.itemTranslationFieldRules(this.documentItemTypeId, fieldName, rule)
    },
    async remove() {
      try {
        await this.deleteDocumentItemTranslation(this.documentItemTranslationLocal.id)

        this.$router.push({
          name: 'documentitem_edit',
          params: {
            documentitemid: this.$route.params.documentitemid
          }
        })
      } catch (err) {
        this.error.show = true
        this.error.message = `There was a failure in deleting the record. Details: ${JSON.stringify(err)}`
      }
    },
    isRenderComponent (componentName) {      
      return this.renderComponent(this.documentItemTypeId, componentName)
    },
    async create() {
      try {
        await this.createDocumentItemTranslation({ payload: this.documentItemTranslationLocal })
        this.$store.dispatch('app/setSnackBar', 'New Document Item Translation added.')
        this.$router.push({
          name: 'documentitem_edit',
          params: {
            documentitemid: this.$route.params.documentitemid
          }
        })
      } catch (err) {
        this.error.show = true
        this.error.message = `There was a failure in creating the record. Details: ${err}`
      }
    },
    async update() {
      this.save(this.documentItemTranslationLocal)
    },
    determineSave() {
      this.$refs.document_item_translation.validate().then(async (isValid) => {
        if (!isValid) { 
          this.error.show = true
          this.error.message = 'Please fill in required fields'

          return
        }

        try {
          this.isNewRecord ? this.create() : this.update()
          this.changes(false)
        } catch (err) {
          this.error.show = true
          this.error.message = `Error savings error=${err}`
        }
      })
    },
    async loaddata() {
      const documentItemTranslationId = this.$route.params.documentitemtranslationid
      const item = this.getDocumentItemTranslationsById(documentItemTranslationId)

      if (!item && !this.documentItemTranslations) {
        await this.$store.dispatch('documentItems/getDocumentItemWithChildren', this.$route.query.documentitemid)
      }
      
      this.documentItemTranslationLocal = _.cloneDeep(item)
      if (!this.documentItemTranslationLocal) {
        this.initData()
      }
    },
    initData() {
      this.documentItemTranslationLocal = {}
      this.documentItemTranslationLocal.documentitemid = this.$route.params.documentitemid ? this.$route.params.documentitemid : null
    },
    updateLocal (field, value) {
      Vue.set(this.documentItemTranslationLocal, field, value)
      Vue.set(this, 'changed', true)
    },
    changes (changes) {
      this.$emit('pendingChanges', changes)
      this.changed = changes
    }
  }
}
</script>