<template>
  <v-autocomplete
    v-model="localSelected"
    :items="items"
    :loading="isLoading"
    :label="label"
    tile
    chips
    color="blue-grey lighten-2"
    item-text="englishtitle"
    item-value="documentitemid"
    :hint="hint"
    multiple
    return-object
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="$emit('removeItem', data.item.documentitemid)"
      >
        <v-avatar color="primary" left>
          {{ data.item.number }}
        </v-avatar>
        ({{ data.item.documentitemid }}) {{ documentItemTypeName(data.item.documentitemtypeid) }} {{ data.item.number }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template>
        <v-list-item-avatar>
          <v-avatar
            color="red"
            size="56"
            class="white--text"
          >
            {{ data.item.number }}
          </v-avatar>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ data.item.englishtitle }}</v-list-item-title>
          <v-list-item-subtitle class="caption">
            {{ documentItemTypeName(data.item.documentitemtypeid) }}, documentitemid={{ data.item.documentitemid }} 
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import DocAdmin from '@/store/data'
import { mapGetters, mapState } from 'vuex'
import types from '@/helpers/types'

export default {
  name: 'DocumentItemLookupField',
  props: {
    parentDocumentItemId: {
      type: [Number, String],
      default: null
    },
    selected: {
      type: Array,
      default: null
    },
    label: {
      type: String,
      default: 'Document Items'
    },
    hint: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    isLoading: true,
    items: []
  }),
  computed: {
    ...mapGetters('documentItems', ['getDocumentItemsByParent']),
    localSelected: {
      get() { return this.selected },
      set(localSelected) {this.$emit('addItem', localSelected)}
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      DocAdmin.documentItems.list({ params: { parentdocumentitemid: this.parentDocumentItemId } }).then((res) => {
        const { count, results } = res.data
        this.items = results
      }).catch((err) => {
        console.error(`DocumentItemLookupField.error =${err}`)
      }).finally(() => (this.isLoading = false))
    },
    documentItemTypeName (typeId) {
      return types.getDocumentItemTypeNameById(typeId)
    }
  }
}
</script>